// Colors
$magenta: hsl(330, 100%, 50%);
$blue: hsl(200, 90%, 55%);
$green: hsl(85, 80%, 45%);
$orange: hsl(35, 100%, 50%);
$gray: hsl(220, 10%, 50%);

// Fonts
$body-font: Roboto, "Helvetica Neue", sans-serif;
$heading-font: Didot, 'Didot LT STD', "Bodoni 72", Bodoni, 'Bodoni MT', 'Libre Bodoni', 'Hoefler Text', Cambria, Georgia, serif;
$mono-font: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;

// Layout
$content-width: 1050px;
$content-offset: 21rem;

@mixin run-in {
	display: inline-block;
	vertical-align: text-bottom;
	padding: .25em .5em .15em;
	margin-left: -.3em;
	margin-right: .3em;
	border-radius: .3em;
	color: white;
	font: bold 55%/1.5 $body-font;
	text-transform: uppercase;
}

@mixin hide-accessibly {
	border: 0;
	clip: rect(0 0 0 0);
	width: 1px;
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
}

@function tint($color, $percentage) {
	@return mix(white, $color, $percentage);
}

@function shade($color, $percentage) {
	@return mix(black, $color, $percentage);
}

@function color-image($color) {
	@return linear-gradient($color, $color);
}
