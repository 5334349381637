@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900");
@font-face {
  font-family: "Libre Bodoni";
  src: url("fonts/LibreBodoniBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Libre Bodoni";
  src: url("/assets/fonts/libre-bodoni/0f49c646c8f12a9dbd4730215d10a2a8/629d903227943776cbe3783d8cc1e84b/LibreBodoniBoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Libre Bodoni";
  src: url("/assets/fonts/libre-bodoni/0f49c646c8f12a9dbd4730215d10a2a8/bb68fe30ffd78ce5863852f076341165/LibreBodoniItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Libre Bodoni";
  src: url("fonts/LibreBodoniRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Mavo Icons";
  src: url("fonts/mavo-icons.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
:root {
  --color-magenta: #ff0080;
  --color-blue: #25aff4;
  --color-green: #82cf17;
  --color-orange: #ff9500;
  --font-body: Roboto, Helvetica Neue, sans-serif;
  --font-heading: Didot, Didot LT STD, Bodoni 72, Bodoni, Bodoni MT, Libre Bodoni, Hoefler Text, Cambria, Georgia, serif;
  --font-mono: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
  --content-width: 1050px;
  --content-offset: 21rem;
}

body {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  margin: 0;
  font: 300 106%/1.5 Roboto, "Helvetica Neue", sans-serif;
  font-kerning: auto;
  hyphens: auto;
  counter-reset: figure;
  padding: 0 1rem;
}

a {
  color: #25aff4;
  text-decoration: none;
  font-weight: bold;
}
a:hover {
  text-decoration: underline;
}

p {
  hyphens: auto;
}

dd + dt {
  margin-top: 1.5em;
}

ul {
  padding-left: 1em;
}

section pre {
  padding: 1em 1.5em;
  margin: 0.5em -1.5em;
  clip-path: polygon(0 0, 100% 0.5em, calc(100% - .5em) calc(100% - 1em), 0 100%);
}
@supports not (clip-path: polygon(0 0)) {
  section pre {
    border-radius: 0.5em;
  }
}

pre > code a[aria-label],
pre > code mark[aria-label] {
  position: relative;
  background: rgba(37, 175, 244, 0.15);
  border: 1px solid rgba(37, 175, 244, 0.1);
  padding: 0.16em 0.666ch 0.1em;
  border-radius: 0.3em;
  color: inherit;
  text-shadow: 0 1px 1px white;
}
pre > code a[aria-label]::after,
pre > code mark[aria-label]::after {
  position: absolute;
  top: 1.5em;
  left: 0;
  z-index: 1;
  white-space: normal;
  width: 15em;
  padding: 0.8em 1.2em 2em;
  background: white url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 900 60">			<text y="1em" style="font: bold 40px/1 Roboto, Helvetica Neue, sans-serif" opacity=".4">Click to learn more about this feature!</text>		</svg>') no-repeat bottom 0.7em left 1.2em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.4em;
  box-shadow: 0.1em 0.1em 0.5em rgba(0, 0, 0, 0.2);
  color: black;
  font-weight: 400;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  text-wrap: pretty;
}
pre > code a[aria-label].right::after,
pre > code mark[aria-label].right::after {
  left: auto;
  right: 0;
}
pre > code a[aria-label]:hover, pre > code a[aria-label]:focus,
pre > code mark[aria-label]:hover,
pre > code mark[aria-label]:focus {
  background: rgba(255, 149, 0, 0.3);
  border-color: rgba(255, 149, 0, 0.2);
  text-decoration: none;
}
pre > code a[aria-label]:hover::after, pre > code a[aria-label]:focus::after,
pre > code mark[aria-label]:hover::after,
pre > code mark[aria-label]:focus::after {
  content: attr(aria-label);
}

button {
  cursor: pointer;
}

.flat-button {
  display: inline-block;
  padding: 0.2em 0.8em;
  border: 2px solid white;
  border-color: var(--color);
  border-radius: 0.3em;
  color: white;
  color: var(--color);
  font-size: 130%;
  font-weight: 400;
  text-decoration: none;
}
.flat-button:hover {
  background: var(--color);
  color: var(--background);
}

.plain-button, .example.side-by-side form .play {
  display: inline-block;
  vertical-align: 0.1em;
  padding: 0.35em 0.5em 0.25em;
  background: #e3e5e8;
  border: none;
  border-radius: 0.3em;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 75%;
  font-weight: bold;
  text-transform: uppercase;
}
.plain-button:hover, .example.side-by-side form .play:hover {
  background: #ff0080;
  color: white;
}
.plain-button:focus, .example.side-by-side form .play:focus {
  background: #ff9500;
  color: white;
}

.call-to-action.button {
  display: inline-block;
  width: fit-content;
  margin: auto;
  padding: 0.5em 1em 0.4em;
  border-radius: 0.3em;
  background: #82cf17;
  color: white;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
}
.call-to-action.button:hover, .call-to-action.button:focus {
  background: #ff0080;
}

.buttons {
  text-align: center;
}

hr {
  height: 0.5em;
  width: 5em;
  margin: 1.5rem 0 1rem;
  background: #ff0080;
  border: none;
}

input {
  font: inherit;
  color: inherit;
  border: none;
  border-bottom: 0.1em solid rgba(0, 0, 0, 0.4);
}

body > header,
body > footer {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

body > header {
  position: relative;
  z-index: 1;
  display: flex;
  padding: 0.8em 1em;
  margin: 0 -1rem;
  justify-content: space-between;
  flex-flow: row;
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 1.5em), 0% 100%);
  background: #222;
  color: white;
}
body > header .logo {
  margin: 0;
}
body > header .logo img {
  display: block;
  height: 2.2rem;
}
body > header + h2 {
  padding: 1.5em 1em 0.5em;
  margin: -1.5em -1rem 2rem;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% calc(100% - 1em));
  background: #ff0080;
  color: white;
  text-align: right;
  font: 200 250%/1 Roboto, "Helvetica Neue", sans-serif;
}
body > header nav {
  display: flex;
  font-weight: 700;
  margin-bottom: 1.5em;
}
@media (max-width: 900px) {
  body > header nav {
    flex-wrap: wrap;
  }
  body > header nav a {
    min-width: 6em;
  }
}
body > header nav a {
  display: block;
  padding: 0 1em;
  border-radius: 2em;
  line-height: 2;
  color: white;
}
body > header nav a:hover {
  background: white;
  color: black;
  mix-blend-mode: screen;
  text-decoration: none;
}
body > header nav iframe {
  display: none;
}
@supports (clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 1.5em), 0% 100%)) {
  html:not(.home) body > header nav {
    transform: rotate(-1deg);
    transform-origin: right;
  }
}

a.soon {
  cursor: not-allowed;
}
a.soon:not(:hover):not(:focus) {
  color: rgba(255, 255, 255, 0.7);
}
a.soon::after {
  content: "Soon";
  display: inline-block;
  vertical-align: middle;
  padding: 0.4em 0.5em 0.3em;
  margin-left: 0.5em;
  border-radius: 0.3em;
  background: #ff9500;
  color: white;
  text-transform: uppercase;
  font-size: 60%;
  line-height: 1;
}

h1, h2 {
  font-family: Didot, "Didot LT STD", "Bodoni 72", Bodoni, "Bodoni MT", "Libre Bodoni", "Hoefler Text", Cambria, Georgia, serif;
  font-weight: bold;
}
h1 > a, h2 > a {
  color: inherit;
}

body > section > h1,
body > .mv-container > section > h1,
.h1 {
  font: bold 200% Didot, "Didot LT STD", "Bodoni 72", Bodoni, "Bodoni MT", "Libre Bodoni", "Hoefler Text", Cambria, Georgia, serif;
  color: #ff9500;
}

body > section > h2,
body > section > div > h2,
body > section > section > h1,
body > .mv-container > section > h2,
body > .mv-container > section > div > h2,
body > .mv-container > section > section > h1,
.h2 {
  font: bold 150% Didot, "Didot LT STD", "Bodoni 72", Bodoni, "Bodoni MT", "Libre Bodoni", "Hoefler Text", Cambria, Georgia, serif;
  color: #ff0080;
}

body > section,
body > .mv-container > section,
body > div,
.mv-add-section {
  width: 1050px;
  max-width: 90vw;
  margin: auto;
  padding: 1rem 0;
}
@media (max-width: 1150px) {
  body > section,
body > .mv-container > section,
body > div,
.mv-add-section {
    width: auto;
    max-width: 100vw;
    margin: initial;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

body > footer {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  padding: 3em 1em 1em;
  padding: 3em calc(50% - 525px) 1em;
  background: rgba(213, 215, 221, 0.5);
  color: rgba(0, 0, 0, 0.8);
  font-size: 80%;
  clip-path: polygon(0% 0%, 100% 2.5em, 100% 100%, 0% 100%);
  margin: 2rem -1rem 0 -1rem;
}
body > footer nav {
  display: flex;
  font-weight: 700;
  margin-bottom: 1.5em;
}
body > footer nav a {
  display: block;
  padding: 0 1em;
  border-radius: 2em;
  line-height: 2;
  color: white;
}
body > footer nav a:hover {
  background: white;
  color: black;
  mix-blend-mode: screen;
  text-decoration: none;
}
body > footer nav iframe {
  display: none;
}
@supports (clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 1.5em), 0% 100%)) {
  html:not(.home) body > footer nav {
    transform: rotate(-1deg);
    transform-origin: right;
  }
}
body > footer p:first-child {
  width: 100%;
  font-size: 150%;
}
body > footer > article {
  flex: 1;
  margin: 0 1em 0 0.5em;
}
body > footer > article > h1 {
  font-size: 100%;
  text-transform: uppercase;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  opacity: 0.4;
}
body > footer > article:first-of-type {
  flex: 2;
}
body > footer > article:first-of-type ul {
  columns: 2;
}
body > footer p {
  margin: 0.5em;
}
body > footer a {
  color: inherit;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.7);
}
body > footer a.logo {
  height: 5em;
  margin: 0 0.5em;
  align-self: center;
}
body > footer a.logo img {
  height: 100%;
}
body > footer ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
body > footer ul a {
  display: block;
  padding: 0.3em 0;
}
body > footer .netlify::before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  margin-right: 0.1em;
  opacity: 0.4;
  background: url("https://www.netlify.com/img/global/favicon/safari-pinned-tab.svg") no-repeat center/100% 100%;
}
@media (min-width: 1300px) and (max-width: 1650px) {
  body > footer {
    padding-left: 21rem;
  }
}

.notice {
  padding: 0.5em 1em;
  border-radius: 0.3em;
  background: #d3effd;
  width: auto;
  max-width: 1050px;
}
.notice a {
  font-weight: bold;
}
.notice.warning {
  background: #ffeacc;
}
.notice.warning::before {
  content: "⚠️ ";
  filter: hue-rotate(-10deg);
  mix-blend-mode: multiply;
}
.notice.warning a {
  color: #ff9500;
  mix-blend-mode: multiply;
}

.note, .tip, .warning {
  border-left: 0.5em solid #a1db51;
  background: #ecf8dc;
  padding: 0.5em;
  margin-left: -1em;
}
.note::before, .tip::before, .warning::before {
  content: "Note: ";
  font-weight: bold;
  color: #82cf17;
}

.tip {
  border-left: 0.5em solid #66c7f7;
  background: #e9f7fe;
}
.tip::before {
  content: "Tip: ";
  color: #25aff4;
}

.warning {
  border-left-color: #ff9500;
  background: #fff4e6;
}
.warning:not(.notice)::before {
  content: "Warning: ";
  color: #ff9500;
}

.version {
  display: inline-block;
  vertical-align: text-bottom;
  padding: 0.5em 0.6em 0.4em;
  border-radius: 0.3em;
  --color: #25aff4;
  color: var(--color);
  border: 1px solid var(--color);
  font-size: 70%;
  line-height: 1;
  font-weight: bold;
}
.version:hover {
  background: var(--color);
  color: white;
  text-decoration: none;
}

figure {
  counter-increment: figure;
}
figure img, figure video {
  max-width: 100%;
}
figure figcaption {
  font-style: italic;
}
figure figcaption::before {
  content: "Figure " counter(figure) " ";
  font-weight: bold;
  font-style: normal;
}

body > section table,
body > .mv-container > section table {
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
}
body > section table th, body > section table td,
body > .mv-container > section table th,
body > .mv-container > section table td {
  text-align: left;
}
body > section table th,
body > .mv-container > section table th {
  padding: 0 0.5em;
}
body > section table td, body > section table tbody th,
body > .mv-container > section table td,
body > .mv-container > section table tbody th {
  padding: 0.2em 0.5em;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.example {
  margin-bottom: 1em;
}
.example > h1, .example > header > h1 {
  color: #ff0080;
  font-size: 150%;
}
.example h6 {
  font: bold 120% Didot, "Didot LT STD", "Bodoni 72", Bodoni, "Bodoni MT", "Libre Bodoni", "Hoefler Text", Cambria, Georgia, serif;
}
.example h6::before {
  content: "🍭";
  margin-right: 0.2em;
}
.example .example-container {
  position: relative;
  transform: scale(1);
  padding: 1.2em 1em 1em;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin: 1em 0 0;
}
.example .example-container iframe:only-child {
  display: block;
  width: 100%;
  min-height: 100%;
  border: none;
}
.example .example-container input[type=number] {
  font-size: 150%;
  max-width: 2em;
}
.example .example-container [mv-multiple],
.example .example-container [mv-list-item] {
  padding: 0.5em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0.5em 0;
}
.example .example-container [mv-multiple] h1,
.example .example-container [mv-list-item] h1 {
  margin-top: 0;
}
.example .example-container span[mv-multiple],
.example .example-container span[mv-list-item] {
  display: inline-block;
}
.example .example-container.embedded {
  padding: 0;
}
.example.side-by-side {
  margin-bottom: 0;
}
@media (min-width: 1000px) {
  .example.side-by-side > .demo-container {
    display: flex;
    flex-flow: row;
    align-items: stretch;
    justify-content: stretch;
    position: relative;
  }
}
.example.side-by-side > h1, .example.side-by-side > h2,
.example.side-by-side h6, .example.side-by-side > header {
  margin: 0;
  margin-bottom: 0.5em;
}
.example.side-by-side h6 {
  display: flex;
}
.example.side-by-side form {
  margin-left: auto;
}
.example.side-by-side form .play::before {
  content: "✎";
  display: inline-block;
  margin-right: 0.3em;
}
@keyframes wiggle {
  from {
    transform: rotate(-10deg);
  }
  to {
    transform: rotate(10deg);
  }
}
.example.side-by-side form .play:hover::before {
  animation: 0.2s wiggle infinite alternate;
}
.example.side-by-side pre {
  clip-path: none;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.3em;
  font-size: 87%;
  resize: horizontal;
}
.example.side-by-side pre code {
  tab-size: 2;
}
@media (min-width: 1000px) {
  .example.side-by-side pre {
    margin-right: 1em;
  }
}
.example.side-by-side .example-container {
  margin: 0;
  flex: 1;
  min-width: 30%;
}
.example.side-by-side pre, .example.side-by-side .example-container {
  margin-bottom: 1rem;
}

body > section#intro {
  font-size: 200%;
  hyphens: manual;
}
body > section#intro p:first-child {
  margin-top: 0;
}
body > section#intro p:last-child {
  margin-bottom: 0;
}
body > section#intro p::before {
  display: inline-block;
  vertical-align: text-bottom;
  padding: 0.25em 0.5em 0.15em;
  margin-left: -0.3em;
  margin-right: 0.3em;
  border-radius: 0.3em;
  color: white;
  font: bold 55%/1.5 Roboto, "Helvetica Neue", sans-serif;
  text-transform: uppercase;
  background: #abb0ba;
}

body > section.mv-bar.mv-ui {
  width: max-content;
  margin: auto;
}

section.mv-logged-out p {
  max-width: 100%;
  margin-left: auto;
  padding: 0.5em 0.8em;
  text-align: right;
  background-color: whitesmoke;
  border-radius: 0.3em;
}
section.mv-logged-out p .mv-login {
  text-decoration: none;
}
section.mv-logged-out p .mv-login:not(:hover) {
  color: #abb3ba;
}
section.mv-logged-out p .mv-login::before {
  content: "✎ ";
}

pre, code {
  background: whitesmoke;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  tab-size: 4;
  hyphens: none;
  white-space: pre;
}

code {
  background: transparent;
}

/* Code blocks */
pre {
  padding: 1em;
  margin: 0;
  overflow: auto;
  background: #f9fafb;
  background: linear-gradient(to bottom, #f6f8f9 50%, #f3f5f7 0) 0 0/3em 3em content-box border-box;
  text-align: left;
}

:not(pre) > code {
  font-weight: bold;
  color: #505662;
  white-space: pre-wrap;
}

/**
 * Syntax highlighting
 */
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: slategray;
}

.token.punctuation {
  color: #999;
}

.namespace {
  opacity: 0.7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #ff0080;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #75ba15;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #a67f59;
  background: rgba(255, 255, 255, 0.5);
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #25aff4;
}

.token.function {
  color: #DD4A68;
}

.token.regex,
.token.important,
.token.variable {
  color: #e90;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}